button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

#root > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  width: 100%;
}

select {
  -webkit-appearance: menulist;
  font-size: 16px;
}

.container {
  flex-direction: column;
  /* width:100%; */
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items:center; */
  background-color: #ffffff;
  padding: 20px;
  text-align: justify;
  padding-bottom: 0;
}
.titleBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  color: #3d76f8;
  margin: 5px 0;
  font-weight: bold;
}
.page {
  color: #3d76f8;
  /* font-size: 18; */
  font-weight: bold;
  width: 100%;
  margin-top: 5px;
}
.blueText {
  color: #3d76f8;
}
.greyText {
  color: #6d6d6d;
}
.contentText {
  color: #6d6d6d;
  font-size: 16px;
}
.tncText {
  color: #6d6d6d;
  font-size: 14px;
}
.tncText > div {
  margin-bottom: 20px;
}

.button {
  margin-top: 20px;
  background-color: #3d76f8;
  padding: 15px 10px;
  white-space: nowrap;
  border: none;
  border-radius: 20px;
  width: 100%;
  align-items: center;
}

.bottomMargin {
  margin-bottom: 100px;
}

.nextButton {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #3d76f8;
  padding: 15px 10px;
  border: none;
  border-radius: 20px;
  width: 100%;
  align-items: center;
}

.disabledButton {
  margin-top: 20px;
  background-color: #6d6d6d;
  padding: 15px 10px;
  border: none;
  border-radius: 20px;
  width: 100%;
  align-items: center;
}
.buttonText {
  text-align: center;
  /* font-size: 12px; */
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.merchantLogo {
  object-fit: contain;
  height: 20px;
  width: 20px;
}

.dropdownIcon {
  top: "100%";
  width: 0;
  height: 0;
  border-width: 5;
  border-bottom-width: 0;
  border-style: "solid";
  border-color: "transparent";
  border-top-color: "#6D6D6D";
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-code-input {
  margin: 10px 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.react-code-input > input {
  text-align: center;
}

.loadingLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoIcon {
  height: 35px !important;
  width: 35px !important;
}

.datePicker {
  width: 100%;
}

.modal_title {
  color: #3d76f8;
  font-size: 18px;
}

.modal_subtitle {
  font-weight: bold;
  color: #777777;
  font-size: 14px;
}

.modal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 40px;
  width: 240px;
  /* height: 450px; */
  height: auto;
  background-color: white;
}

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
}

.modal_icon {
  font-size: 12px;
  width: 20px !important;
  color: #6d6d6d;
}

.modal_text {
  color: #6d6d6d;
  font-size: 12px;
  line-height: 2;
}

.modal_img {
  object-fit: contain;
  width: 100%;
}

.isVerified {
  background-color: #59ce55c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

.isNotVerified {
  margin: 20px 0;
  background-color: #d82323c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

.isLoading {
  background-color: #abacabc9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

.completeContainer {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.subtitle {
  color: #6d6d6d;
  font-weight: bold;
  font-size: 16px;
}

.previewSubtitle {
  margin-bottom: 5px;
  font-weight: bold;
  color: #3d76f8;
}

.previewField {
  color: #6d6d6d;
  margin-right: 5px;
}

.previewSection > div {
  margin: 10px 0;
  flex-wrap: wrap;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.Toastify__toast,
.Toastify__toast--rtl,
.Toastify__toast--dark,
.Toastify__toast--default,
.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast-body {
  background-color: #3d76f8;
  color: white;
}

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: none;
}

.video_icon {
  font-size: 14px;
}

.rhap_progress-container {
  display: none !important;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  /* -webkit-user-select: none; */
}

.rhap_progress-section {
  justify-content: space-between;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 100%;
  /* margin: 0 auto; */
  height: 100%;
  /* max-height: 70vh; */
  /* margin-top: calc(100vh - 85vh - 20px); */
  background: #fff;
  /* border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto; */
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .container {
    flex-direction: column;
    align-self: center;
    width:50%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* align-items:center; */
    background-color: #ffffff;
    padding: 20px;
    text-align: justify;
    padding-bottom: 0;
  }

  .button {
    margin-top: 20px;
    align-self: center;
    background-color: #3d76f8;
    padding: 15px 0px;
    border: none;
    border-radius: 20px;
    width: 300px;
    max-width: 100%;
  }
  .disabledButton {
    margin-top: 20px;
    align-self: center;
    background-color: #6d6d6d;
    padding: 15px 10px;
    white-space: nowrap;
    border: none;
    border-radius: 20px;
    width: 300px;
    max-width: 100%;
  }
}