.container{
    height: 60px;
    padding: 0 15px;
    display:flex;
    border-color: #DCDCDC;
    border-style: groove;
    border-width: 0 0 1px 0;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.container > div{
    width: 30px;
}