.centerizedContainer {
  display: flex;
  justify-content: center;
}

.idCard {
  width: 40%;
  flex: 1;
  align-self: flex-start;
  /* height:100px; */
  margin-top: 10px;
  object-fit: contain;
}

.idCardFront,
.idCardBack {
  width: 100%;
  flex: 1;
  margin-top: 10px;
  max-height: 200px;
  align-self: flex-start;
  object-fit: contain;
}

.idCardContainer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.dashedContainer {
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1px;
  border-style: dashed;
  border-width: 1px;
  border-color: #3d76f8;
  margin-bottom: 20;
  align-items: center;
  padding: 10px;
}

.subtitle {
  color: #3d76f8;
  font-weight: bold;
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  /* .idCardFront,
  .idCardBack {
    width: 100%;
    flex: 1;
    margin-top: 10px;
    max-height: 200px;
    align-self: center;
    object-fit: contain;
}

.dashedContainer {
  margin: 15px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1px;
  border-style: dashed;
  border-width: 1px;
  border-color: #3d76f8;
  margin-bottom: 20;
  align-items: center;
  padding: 10px;
} */
}