.codeBox{
    width:100%;
    display: flex;
    border: 1px solid #D6D6D6;
}

.codeSelector{
    flex:1;
    text-align-last:center;
    border-style: hidden;
    background-color: transparent;
    font-size: 16px;

}




.codeInput{
    flex:4;
    height:40px;
    padding: 0 10px;
    border-style: hidden;
    font-size: 16px;
}

.resend{
    border-style: hidden;
    color: #D6D6D6;
    font-size: 14px;
}

.resendText{
    margin: 5px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 1.5;
}