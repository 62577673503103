.centerizedContainer {
  display: flex;
  justify-content: center;
}

.idCard {
  width: 40%;
  flex: 1;
  /* height:100px; */
  margin-top: 10px;
  object-fit: contain;
}

.faceImage {
  width: 120px;
  align-self: flex-start;
  flex: 1;
  z-index: 0;
  margin-top: 10px;
  object-fit: contain;
}

.idCardContainer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.dashedContainer {
  margin: 15px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1px;
  border-style: dashed;
  border-width: 1px;
  border-color: #3d76f8;
  margin-bottom: 20;
  align-items: center;
  padding: 10px;
}

.subtitle {
  color: #3d76f8;
  font-weight: bold;
  font-size: 16px;
}

.image_container {
  margin: 30px 0;
}

.example_container {
  margin: 10px 0;
}

.example {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.example_photo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.example_text {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.example_text > div {
  line-height: 1.5;
}

.loading {
  background-color: #788578c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.isVerified {
  background-color: #59ce55c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.isNotVerified {
  background-color: #d82323c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -15px;
  right: -15px;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */

}