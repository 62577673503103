.image {
  width: 100%;
  flex: 1 1;
  margin-top: 10px;
  object-fit: contain;
  max-height: 200px;
  align-self: flex-start;
}

.idCardContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  width: 80%;
  height: fit-content;
}

.dashedContainer {
  margin: 15px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1px;
  border-style: dashed;
  border-width: 1px;
  border-color: #3d76f8;
  margin-bottom: 20;
  align-items: center;
  padding: 10px;
  position: relative;
}

.flexRow {
  flex-wrap: wrap;
  display: flex;
}

.previewField {
  color: #6d6d6d;
  white-space: nowrap;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
