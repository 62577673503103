.form {
  display: flex;
  flex-direction: column;
}

.form > * {
  margin-top: 5px;
}

.errorText {
  color: red;
  font-size: 12px;
}

.formDes {
  color: #919191;
  font-size: 12px;
  margin-top: 5px;
}

.formLabel {
  color: #6d6d6d;
  font-weight: bold;
  /* font-size: 14px; */
  font-size: 16px;
  margin-top: 15px;
  display: flex;
}

.formLabel > div > div {
  display: inline;
}

.formInput {
  height: 20px;
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;

  border-color: #dfdfdf;
}

.next {
  color: green;
}

.selector {
  height: 30px;
  padding: 0 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  font-size: 16px;
  background-color: transparent;
}

/* .datepicker, .react-datepicker-wrapper{
    display:flex!important;
    width:100%!important;
}

.datepicker input{
    height: 20px;
    width: 100%;
    padding: 5px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #DFDFDF;
} */

.datePicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.datePicker > div {
  display: flex;
  width: 100%;
}

.datePicker > p {
  color: red;
  /* font-size: 12px; */
  font-size: 16px;
}

.datePicker > div > label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  color: #6d6d6d;
  font-weight: bold;
  font-size: 14px;
  flex: 1;
}

.datePicker > div > label:last-child {
  margin-right: 0px;
}

.datePicker > div > label > select {
  height: 30px;
  border-width: 1px;
  font-size: 16px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: transparent;
  padding: 0 10px;
}

.datePickerComponent {
  height: 30px;
  width: 32.5%;
  border-width: 1px;
  font-size: 16px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: transparent;
  padding: 0 10px;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.checkboxes > div {
  min-width: 30vw;
}

.button {
  margin: 5px 0;
}

.checkBoxCheckedOption {
  margin: 10px 0;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  display: flex;
  border-color: #3d76f8;
  margin-right: 10px;
}

.checkBoxUncheckedOption {
  margin: 10px 0;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  display: flex;
  margin-right: 10px;
}

.checkedOption {
  margin: 5px 0;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  display: flex;
  border-color: #3d76f8;
}

.uncheckedOption {
  margin: 5px 0;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  display: flex;
}

.checkBoxOptionBox {
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #3d76f8;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 20px;
  margin-top: 2px;
}

.optionBox {
  width: 20px;
  height: 20px;
  border-radius: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: #3d76f8;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 2px;
  min-width: 20px;
}

.checkedOptionBall {
  width: 60%;
  height: 60%;
  border-radius: 20px;
  background-color: #3d76f8;
}

.optionBall {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 20px;
}

.optionTextBox {
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.optionText {
  font-size: 15px;
  width: 100%;
  height: 100%;
  color: #808080;
  /* text-align: center; */
  text-align: justify;
}
